export const accessoriesBrand = [
  {
    url: require("../assets/accessories-head.jpeg"),
    title: "Channel",
    brand: "channel",
    link: "accessorie",
    desc: "Luxury Accessories",
    id: 1,
  },
  {
    url: require("../assets/perfumes-head.jpeg"),
    title: "Armani Exchange",
    brand: "armani",
    link: "accessorie",
    desc: "Luxury Accessories",
    id: 2,
  },
  {
    url: require("../assets/make-up-head.jpeg"),
    title: "Denvosi",
    link: "accessorie",
    desc: "Luxury Accessories",
    id: 3,
  },
];
export const handbagBrands = [
  {
    url: require("../assets/accessories-head.jpeg"),
    title: "Channel",
    brand: "channel",
    link: "hand-bag",
    desc: "Luxury HandBags",
    id: 1,
  },
  {
    url: require("../assets/perfumes-head.jpeg"),
    title: "Armani Exchange",
    brand: "armani",
    link: "hand-bag",
    desc: "Luxury HandBags",
    id: 2,
  },
  {
    url: require("../assets/make-up-head.jpeg"),
    title: "Denvosi",
    link: "hand-bag",
    desc: "Luxury HandBags",
    id: 3,
  },
];
export const makeupBrands = [
  {
    url: require("../assets/accessories-head.jpeg"),
    title: "Channel",
    brand: "channel",
    link: "make-ups",
    desc: "Luxury Makeup",
    id: 1,
  },
  {
    url: require("../assets/perfumes-head.jpeg"),
    title: "Armani Exchange",
    brand: "armani",
    link: "make-ups",
    desc: "Luxury Makeup",
    id: 2,
  },
  {
    url: require("../assets/make-up-head.jpeg"),
    title: "Denvosi",
    link: "make-ups",
    desc: "Luxury Makeup",
    id: 3,
  },
];
export const skinBrands = [
  {
    url: require("../assets/accessories-head.jpeg"),
    title: "Channel",
    brand: "channel",
    link: "skin-hair-cares",
    desc: "Luxury Skin Care",
    id: 1,
  },
  {
    url: require("../assets/perfumes-head.jpeg"),
    title: "Armani Exchange",
    brand: "armani",
    link: "skin-hair-cares",
    desc: "Luxury Skin Care",
    id: 2,
  },
  {
    url: require("../assets/make-up-head.jpeg"),
    title: "Denvosi",
    link: "skin-hair-cares",
    desc: "Luxury Skin Care",
    id: 3,
  },
];
export const fragranceBrands = [
  {
    url: require("../assets/accessories-head.jpeg"),
    title: "Channel",
    brand: "channel",
    link: "fragrance",
    desc: "Luxury Fragrances",
    id: 1,
  },
  {
    url: require("../assets/perfumes-head.jpeg"),
    title: "Armani Exchange",
    brand: "armani",
    link: "fragrance",
    desc: "Luxury Fragrances",
    id: 2,
  },
  {
    url: require("../assets/make-up-head.jpeg"),
    title: "Denvosi",
    link: "fragrance",
    desc: "Luxury Fragrances",
    id: 3,
  },
];