import bellaLogo from '../assets/bella.png'
import { Link } from "react-router-dom";
import {gsap} from 'gsap';
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { accessoriesBrand, makeupBrands, fragranceBrands, skinBrands, handbagBrands } from './Brands';

const Brand = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  useEffect(() => {
    gsap.from('.mre', {opacity: 0, y: 100});
  }, [])
  const directPage = (loc) => {
    window.location.assign(`${loc}`)
  }
  
  const [brands, setBrands] = useState(props.category === "accessories" ? accessoriesBrand : props.category === "hand-bags" ? handbagBrands : props.category === "fragrances" ? fragranceBrands : props.category === "skin-hair-care" ? skinBrands : props.category === "make-up" ? makeupBrands : '')
  return (
    <div className="relative min-h-[100vh] h-fit flex flex-col justify-center items-center w-full bg-white">
      <div className="ob mb-10"></div>
    <div className="flex flex-col justify-center items-center h-20 w-full relative">
      <Link onClick={() => {directPage("")}} className="absolute top-0 z-10">
      <img src={bellaLogo} width={150} alt=""  />
      </Link>
      <div className="h-1 w-full bg-primary-color relative top-1"></div>
    </div>
    <div className="w-full h-fit md:pt-16 pt-36 text-primary-color flex items-center flex-wrap justify-center light mb-28">
    {brands?.map((brand) => (
    <div className="link-cont mre m-5  flex flex-col gap-2 justify-center items-center" key={brand.id}>
      <div className="h-[18rem] w-[19rem] rounded">
      <img src={brand.url} alt="" className='h-full w-full rounded object-cover' />
      </div>
      <h1 className="bold text-center text-primary-multiply-color">{brand.title}</h1>
      <p className="w-3/4 text-center text-black">{brand.desc}</p>
     <Link to="/accessories/accessorie" state={{brand: brand.brand}}><button className="bg-primary-multiply-color transition-all duration-300 hover:bg-white hover:border hover:border-primary-multiply-color p-2 rounded regular text-white hover:text-primary-multiply-color">Shop</button></Link>
    </div>
  ))}
    </div>
    {/* onClick={() => {directPage(`${props.category}/${brand.link}`)}} */}
    </div>
  )
}

export default Brand