import { motion, useTransform, useScroll } from "framer-motion";
import { Flower } from "lucide-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { cards } from "./cards";
import { useEffect } from "react";
import accessories from '../../assets/accessories-head.jpeg';
import perfumes from '../../assets/perfumes-head.jpeg';
import makeup from '../../assets/make-up-head.jpeg';
import handbags from '../../assets/handbags-head.jpeg';
import skinCare from '../../assets/skin-care-head.jpeg';
import { Link } from "react-router-dom";
const HorizontalScroll = () => {
  const { i18n } = useTranslation();

  const userLanguage = localStorage.getItem('userLanguage') || 'en';
  useEffect(() => {
    // Get the user's preferred language or use a default language

    // Set the initial language and direction
    i18n.changeLanguage(userLanguage);
    document.documentElement.dir = userLanguage === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.lang = userLanguage;

  }, [i18n, userLanguage]);

  return (
    <div className="bg-white h-fit mt-16">
      <h1 className="md:text-3xl text-lg text-primary-multiply-color text-center bold relative top-10 flex flex-col gap-2">Bella Store · is Affiliated with<br /><span className="text-primary-color md:text-5xl text-3xl mx-2">Beauty & Fragrance Services</span><span className="text-2xl">Services Available</span> <Flower className="text-center m-auto"/></h1>
      {userLanguage === 'en' ? 
      <HorizontalScrollCarousel />  : <HorizontalScrollCarouselAr />}
    </div>
  );
};

const directPage = (loc) => {
  window.location.assign(`${loc}`)
}

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

    const x = useTransform(scrollYProgress, [0, 1], ["1%", "-95%"]);

  return (
    <section ref={targetRef} className="relative h-[300vh]" id="categ">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <motion.div style={{ x }} className="flex gap-4 ">
          {cards.map((card) => (
               <Link onClick={() => {directPage(`${card.link}`)}} >
               <div
             className="group relative h-[24rem] w-[22rem] overflow-hidden bg-neutral-200 shadow-2xl shadow-red-400 rounded-2xl border border-red-700"
           >
             <div
               style={{
                 backgroundSize: "cover",
                 backgroundRepeat: 'no-repeat',
                 backgroundPosition: "center center",
                 position: 'relative',
               }}
               className="absolute "
             ></div>
             {/* <img className="overlay absolute top-0 left-0 h-full max-w-[100%] m-auto rounded-2xl inset-0 z-0 transition-transform duration-300 group-hover:scale-110" src={card.url} alt=""></img> */}
        <img src={card.url} alt="a" className="h-[24rem] w-[22rem] rounded object-cover" />
             <div className="absolute inset-0 z-10">
               <p className="p-8 text-6xl bold font-[1000] uppercase text-primary-multiply-color rounded-b-2xl">
                 {card.title}
               </p>
               {/* <p className=" p-8 text-2xl font-black text-white shadow-2xl shadow-white uppercase">
                 {card.desc ? card.desc : ''}
               </p> */}
             </div>
           </div>
              
       </Link>
          ))}
    
      
     
        </motion.div>
      </div>
    </section>
  );
};
const HorizontalScrollCarouselAr = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

    const x = useTransform(scrollYProgress, [0, 1], ["-1%", "95%"]);

  return (
    <section ref={targetRef} className="relative h-[300vh]" id="categ">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <motion.div style={{ x }} className="flex gap-4 ">
          {cards.map((card) => (
               <Link onClick={() => {directPage(`${card.link}`)}} >
               <div
             className="group relative h-[24rem] w-[22rem] overflow-hidden bg-neutral-200 shadow-2xl shadow-red-400 rounded-2xl border border-red-700"
           >
             <div
               style={{
                 backgroundSize: "cover",
                 backgroundRepeat: 'no-repeat',
                 backgroundPosition: "center center",
                 position: 'relative',
               }}
               className="absolute "
             ></div>
             {/* <img className="overlay absolute top-0 left-0 h-full max-w-[100%] m-auto rounded-2xl inset-0 z-0 transition-transform duration-300 group-hover:scale-110" src={card.url} alt=""></img> */}
        <img src={card.url} alt="a" className="h-[24rem] w-[22rem] rounded object-cover" />
             <div className="absolute inset-0 z-10">
               <p className="p-8 text-6xl bold font-[1000] uppercase text-primary-multiply-color rounded-b-2xl">
                 {card.title}
               </p>
               {/* <p className=" p-8 text-2xl font-black text-white shadow-2xl shadow-white uppercase">
                 {card.desc ? card.desc : ''}
               </p> */}
             </div>
           </div>
              
       </Link>
          ))}
    
      
     
        </motion.div>
      </div>
    </section>
  );
};
// const Card = ({ card }) => {
//   return (
    
//   );
// };

export default HorizontalScroll;
