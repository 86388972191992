import React, {useState, useLayoutEffect} from 'react'
import logo from '../assets/favicon.ico'
import './CSS/SignUp.css'
import {t} from 'i18next'
import { Link } from 'react-router-dom'
import bellaLogo from '../assets/bella.png'
const SignUp = () => {
  const [formData,setFormData] = useState({firstname:"",lastname: "",email:"",number:"",password:"", address: ""});
  const changeHandler = (e) => {
    setFormData({...formData,[e.target.name]:e.target.value});
    }
   
    const signup = async () => {
      let dataObj;
      await fetch('http://localhost:4000/signup', {
        method: 'POST',
        headers: {
          Accept:'application/form-data',
          'Content-Type':'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((resp) => resp.json())
        .then((data) => {dataObj=data});
  
        if (dataObj.success) {
          localStorage.setItem('auth-token',dataObj.token);
          localStorage.setItem('user', 
            JSON.stringify({firstname: formData.firstname,lastname: formData.lastname ,number: formData.number, email: formData.email})
          )
          
          // localStorage.setItem('username',formData.userName);
          // localStorage.setItem('email',formData.email);
          window.location.replace("/");
        }
        else
        {
          alert(dataObj.errors)
        }
    }
    const login = async () => {
        let dataObj;
        await fetch('http://localhost:4000/login', {
      method: 'POST',
      headers: {
        Accept:'application/form-data',
        'Content-Type':'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((resp) => resp.json())
      .then((data) => {dataObj=data});
      console.log(dataObj);
      if (dataObj.success) {
        localStorage.setItem('auth-token',dataObj.token);
        localStorage.setItem('user', 
        JSON.stringify({firstname: formData.firstname,number: formData.number, lastname:formData.lastname , email: formData.email})
      )
        // localStorage.setItem('username', username)
        // localStorage.setItem('email',formData.email);
        window.location.replace("/");
      }
      else
      {
        alert(dataObj.errors)
      }
  }
 
const container = document.getElementById("container");

        const handleSignUp = () => {
          container.classList.add("right-panel-active");
        }
        const handleSignIn = () => {
              container.classList.remove("right-panel-active")
}
useLayoutEffect(() => {
  window.scrollTo(0, 0)
}, []);
console.log(localStorage.getItem('user'))

const [log, setLog] = useState('signup');
const [size, setSize] = useState(false);
window.addEventListener('resize', () => {
  if(window.innerWidth < 768) {
    setSize(true);
  } else {
    setSize(false);
  }
})
  return (
    <div className='h-[140vh] relative'>
      <div className="ob"></div>
      <div className={`${!size ? 'flex':'hidden'} flex-col justify-center items-center h-20 w-full relative`}>
        <Link to="/" className="absolute top-0 z-10">
        <img src={bellaLogo} width={150} alt=""  />
        </Link>
        <div className="h-1 w-full bg-primary-color relative top-1"></div>
      </div>
     <div className="absolute h-full md:flex hidden w-full -z-30 bg-white" alt=''>
      <div className="circle1"></div>
      <div className="circle2"></div>
      <div className="circle3"></div>
     </div>
     {log === "signup" && size ? <div className="form w-full">
     <h1 className='text-3xl bold text-white'>{t("create")}</h1>
        <span className='regular text-gray-200'>use email for registration</span>
        <input type="text" placeholder="First name" name="firstname" value={formData.firstname} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <input type="text" placeholder="Last Name" name="lastname" value={formData.lastname} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <input type="email" placeholder="Email address" name="email" value={formData.email} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <input type="number" placeholder="Phone Number" name="number" value={formData.number} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
          <input type="password" placeholder="Password" name="password" value={formData.password} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <button className='btnn' onClick={() => {signup()}}>Create Account</button>

     <h1 className='text-xl bold text-white mt-20'>Already have an account?</h1>
     <button className='btnn' onClick={() => {setLog("login")}}>Login</button>
     </div> : size && log==='login' ?
     <div className="form w-full">
       <h1 className='text-3xl bold text-white'>{t("login")}</h1>
        <span className='regular text-gray-200'>Login In with your Account</span>
        <input type="email" placeholder="Email address" name="email" value={formData.email} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
          <input type="password" placeholder="Password" name="password" value={formData.password} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <button className='btnn' onClick={() => {login()}}>Login</button>

        <h1 className='text-xl bold text-white mt-20'>Don't have an account?</h1>
        <button className='btnn' onClick={() => {setLog("signup")}}>Signup</button>
     </div>
      : ''}
      <div className={`w-3/4 h-3/4 ${!size ? 'flex': 'hidden'} justify-center items-center m-auto`}>
      <div class={`container m-auto`} id="container">

    <div class="form sign_up">
      <div className='form'>
        <h1 className='text-3xl bold text-white'>{t("create")}</h1>
        <span className='regular text-gray-200'>use email for registration</span>
        <input type="text" placeholder="First name" name="firstname" value={formData.firstname} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <input type="text" placeholder="Last Name" name="lastname" value={formData.lastname} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <input type="email" placeholder="Email address" name="email" value={formData.email} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <div className="flex gap-2 w-[100%]">
        {/* <select name="countrycode" className='inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color' value={countryNumber}>
          <option value="+20" className='bg-[#ecececdd]'>+20</option>
          <option value="+20" className='bg-[#ecececdd]'>+966</option>
          <option value="+20" className='bg-[#ecececdd]'>+11</option>
          <option value="+20" className='bg-[#ecececdd]'>+22</option>
        </select> */}
        <input type="number" placeholder="Phone Number" name="number" value={formData.number} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        {/* <input type="number" hidden name='number' value={formData.number} onChange={changeHandler} /> */}
        </div>
          <input type="password" placeholder="Password" name="password" value={formData.password} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <button className='btnn' onClick={() => {signup()}}>Create Account</button>
      </div>
    </div>
  
    <div class="form sign_in">
      <div className='form'>
        <h1 className='text-3xl bold text-white'>{t("login")}</h1>
        <span className='regular text-gray-200'>Login In with your Account</span>
        <input type="email" placeholder="Email address" name="email" value={formData.email} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
          <input type="password" placeholder="Password" name="password" value={formData.password} onChange={changeHandler} className='w-[100%] inputt rounded-2xl py-2 px-3 border text-black border-primary-multiply-color'/>
        <button className='btnn' onClick={() => {login()}}>Login</button>
      </div>
    </div>

    <div class="overlay-container" dir='ltr'>
      <div class="overlayy">
        <div class="overlay-pannel overlay-left">
          <img src={logo} alt="l" height={100} width={100} className='relative bottom-10' />
          <h1 className='text-xl bold text-primary-multiply-color'>Already have an account</h1>
          <p className='regular text-primary-multiply-color'>Please Login</p>
          <button id="signIn" onClick={handleSignIn} class="overBtn btnn">Log In</button>
        </div>
        <div class="overlay-pannel overlay-right">
          <img src={logo} alt="l" height={100} width={100} className='relative bottom-10' />
          <h1 className='text-2xl bold text-primary-multiply-color'>Create Account</h1>
          <p className='regular text-primary-multiply-color'>Start Your Journey with Us</p>
          <button id="signUp" class="overBtn btnn" onClick={handleSignUp}>Sign Up</button>
        </div>
      </div>
    </div>
  </div>
      </div>
  </div>
  )
}

export default SignUp