import React, { useContext, useRef, useState,useEffect } from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import logo from '../../assets/favicon.ico'
import { ShopContext } from '../../Context/ShopContext'
import { BaggageClaim, BookHeartIcon, Facebook, Instagram, Phone, ShoppingBag, User2 } from 'lucide-react'
import LangSwitcher from '../LangSwitcher'
// import { useTranslation } from 'react-i18next'

const Navbar = () => {
  // const {t} = useTranslation();
  const {getTotalCartItems, getTotalFavItems} = useContext(ShopContext);

  const menuRef = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    const menuTrig = document.querySelector(".menu-trig");
    const logo = document.querySelector('.logo');
    const ham = document.querySelector('.ham');
    const navInner = document.querySelector('.navbarInner');
    const navList = document.querySelector('.nav-list');
    const line1 = document.querySelector('.line1');
    const line2 = document.querySelector('.line2');
    const line3 = document.querySelector('.line3');
    window.addEventListener('resize', () => {
      if(window.innerWidth < 1200) {
        navList?.classList.add('hidden');
        setOpen(false);
      } else {
        navList?.classList.remove('hidden');
        setOpen(false);
      }
    })
    ham?.addEventListener('click', ()=> {
    if(open===false) {
      setOpen(true)
      navList?.classList.add('h-screen');
      navList?.classList.remove('hidden');
      navList?.classList.add('flex-imp');
      line1.classList.add('bg-white')
      line2.classList.add('bg-white')
      line3.classList.add('bg-white')
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
      line1.classList.remove('bg-white')
      line2.classList.remove('bg-white')
      line3.classList.remove('bg-white')
      navList?.classList.remove('h-screen');
      navList?.classList.add('hidden');
      navList?.classList.remove('flex-imp');
      setOpen(false)
    }
    })
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1) {
        navInner?.classList.add('hidden');
        navInner?.classList.remove('h-16');
        navInner?.classList.add('h-0');
        navbar?.classList.remove('h-36');
        navbar?.classList.add('h-16')
        navbar?.classList.add("glass-nav");
        // navbar?.classList.remove("text-white");
        // navbar?.classList.add("text-black");
        navbar?.classList.add("box-shadow-nav");
        menuTrig?.classList.add("snd-color-imp");
      } else {
        navbar?.classList.remove("glass-nav");
        navInner?.classList.remove('hidden');
        navInner?.classList.remove('h-0');
        navInner?.classList.add('h-16')
        navbar?.classList.remove('h-16')
        navbar?.classList.add('h-36');
        menuTrig?.classList.remove("snd-color-imp");
        navbar?.classList.remove("box-shadow-nav");
        // navbar?.classList.remove("text-black");
        // navbar?.classList.add("text-white");
      }
    });
  })
  const closeNav = (loc) => {
    window.location.assign(`/${loc}`);
    setOpen(false);
  }
  return (
    <div id='nav' className='navbar flex flex-col justify-center text-black h-36 pr-6 pl-6 fixed top-0 w-full light z-30'>
       <div className="navbarInner flex w-full justify-between items-center h-16 border-b-[0.1px] border-black">
        <h1 className="mx-3"><span className="bold">Email us at:</span> bella.eg@gmail.com</h1>
        <div className="social-items flex justify-around items-center cursor-pointer w-[20%]">
          <Link to="/" onClick={() =>{closeNav("/");}}><Instagram height={20} /></Link>
          <Link to="/" onClick={() =>{closeNav("/");}}><Facebook height={20} /></Link>
          <div className="h-8 w-0.5 m-3 bg-gray-400"></div>
          <h1 className="flex justify-center items-center regular text-xl"><Phone className="mx-1" height={15} width={15} /> +20 128 100 4800</h1>
        </div>
      </div>
      <div className="navMain flex w-full justify-between items-center h-20">
        <div className="logo-cont flex justify-center items-center">
      <Link className="nav-logo" onClick={() =>{closeNav("");}}>
        <img src={logo} className='logo h-10 m-1 lg:mx-12 mx-8 cursor-pointer clickable' alt="logo" />
      </Link>
        </div>
        <div className="ham flex-col justify-center items-end gap-2 cursor-pointer hidden z-40">
        <div className="line1 h-0.5 w-5 bg-primary-multiply-color"></div>
        <div className="line2 h-0.5 w-7 bg-primary-multiply-color"></div>
        <div className="line3 h-0.5 w-5 bg-primary-multiply-color"></div>
      </div>
        <div className="nav-list gap-4 w-[90%] items-center justify-around text-sm md[1300px]:flex hidden md:flex-row flex-col md:light light relative" ref={menuRef}>
        <div className="absolute bella-stroke bold hidden cursor-default">B E L L A </div>
        <div className="absolute shop-stroke light hidden"><BaggageClaim height={200} width={200} /></div>
        <Link className='z-10' onClick={() =>{closeNav('');}} >HOME</Link>
        <Link className='z-10' onClick={() =>{closeNav('fragrances');}}>FRANGRANCES</Link>
        <Link className='z-10' onClick={() =>{closeNav("make-up")}}>MAKE UP</Link>
        <Link className="z-10" onClick={() =>{closeNav('accessories')}} >ACCESSORIES</Link>
        <Link className='z-10' onClick={() =>{closeNav("hand-bags");}}>HAND BAGS</Link>
        <Link className='z-10' onClick={() =>{closeNav("skin-hair-care");}} >SKIN & HAIR CARE</Link>
        <Link className='z-10' onClick={() =>{closeNav("about");}}>ABOUT US</Link>
        <Link className='z-10' onClick={() =>{closeNav("contact");}} >CONTACT US</Link>
        <div className="flex gap-10 icons-nav">
        <div className="flex cursor-pointer justify-start items-start" onClick={() => {localStorage.getItem('auth-token') ? closeNav("userpage") : closeNav("signup")}}>
        <User2 /> 
        </div>
      <LangSwitcher />
      <div className="cart flex justify-start items-start clickable cursor-pointer" onClick={() => {closeNav("cart");}}>
        <ShoppingBag />
        {getTotalCartItems()}
      </div>
      <div className="fav flex justify-start items-start clickable cursor-pointer" onClick={() => {closeNav('fav')}} >
        <BookHeartIcon />
        {getTotalFavItems()}
      </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
