import React, {useContext,useEffect, useRef, useLayoutEffect, useState} from 'react'
import { ShopContext } from '../Context/ShopContext'
import { PlusCircle, X} from 'lucide-react';
import {Link} from 'react-router-dom';
import emailjs from "@emailjs/browser"
import Alert from '@mui/material/Alert';
import bella from '../assets/bella.png'

const Checkout = (props) => {

  const {products} = useContext(ShopContext);
  const {cartItems,getTotalCartAmount,removeFromCart, getTotalCartAmountWithShipping} = useContext(ShopContext);
  const [confirmed, setConfirmation] = useState(false)
  const form = useRef();
  const selects = props.selects;  
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_6kz1u3v","template_pwj5znt",form.current, "yF9X9hXsV87yTNt_E").then((result) => {
      console.log(result.text);
      console.log("message sent");
      setConfirmation(true)
      localStorage.removeItem('colors')
      setTimeout(() => {
        window.location.replace("/");
        },3500)
      setTimeout(() => {
      setConfirmation(false)
        },4000)
    },
    (error) => {
      console.log(error.text);
    }
  );
};
const user = JSON.parse(localStorage.getItem('user'));
useLayoutEffect(() => {
  window.scrollTo(0, 0)
}, []);
      const [users, setUsers] = useState();
      
      const getUsers = () => {
        fetch('http://localhost:4000/getuser') 
        .then((res) => res.json()) 
        .then((data) => setUsers(data))
      }
      useEffect(() => {
        getUsers();
      }, [])
     
      const userr = users?.find((e) => e.email === user?.email);

  return (
    <div>
      <div className="ob mb-20"></div>
        {confirmed ? <div className="fixed top-0 h-screen w-full flex justify-center items-center glass-nav">
      <Alert  sx={{ border: '1px solid #ec008b' }} icon={false} variant="outlined" severity="success" className='md:h-[60%] h-[70%] md:w-[50%] w-[70%] flex justify-center items-center border-primary-multiply-color mt-20'>
        <div className="flex flex-col justify-center items-center gap-5">
        <img src={bella} alt="bella-logo" className="m-auto" height={200} width={200} />
         <h1 className="bold text-primary-multiply-color text-5xl text-center">Order Confirmed</h1>
         <p className='text-primary-multiply-color text-xl text-center'>Thank your for your order.</p>
         <p className='text-primary-multiply-color text-md text-center'>Order is forwarded to: {user.email}</p>
         <p className='text-primary-multiply-color text-md text-center'>Estimated Delivery: 2-3 Days</p>
        </div>
      </Alert>
        </div> : ''}
      <div className="my-5 flex justify-center items-center w-full">
        <h1 className='bold text-primary-multiply-color text-3xl md:text-6xl sm:text-4xl'>Checkout Payment</h1>
      </div>
      <div className="h-fit my-20 flex flex-col justify-center items-center">

          <div className="flex flex-wrap gap-5 justify-center items-center my-10">
           <h1 className='regular text-3xl text-primary-multiply-color mx-2'>Choose payment method:</h1>
           <button className='p-2 px-4 rounded transition-all duration-300 ease-in-out hover:scale-105 border border-primary-multiply-color'>Cash</button>
           <button className='p-2 px-4 rounded transition-all duration-300 ease-in-out hover:scale-105 border border-primary-multiply-color'>Visa</button>
           <button className='p-2 px-4 rounded transition-all duration-300 ease-in-out hover:scale-105 border border-primary-multiply-color'>Mobile Wallet</button>
          </div>
          <div className="flex flex-col justify-center items-center w-[95%] md:w-[60%] h-fit py-10 border border-primary-multiply-color rounded">
            <h1 className=' text-primary-multiply-color text-5xl bold mb-10'>Cash</h1>
            {products.map((e)=>{

if(cartItems[e.id]>0)
{
  return  <div className='mb-10 w-3/4'>
            <div className="cartitems-format-main cartitems-format border-b-4">
              <img className='w-20' src={e.image} alt="" />
              <p cartitems-product-title>{e.name}</p>
              <p>${e.new_price}</p>
              <button className="cartitems-quantity">{cartItems[e.id]}</button>
              <p>${e.new_price*cartItems[e.id]}</p>
              <X onClick={()=>{removeFromCart(e.id)}} height={25} width={25} className='hover:cursor-pointer'  />
            </div>
             <hr />
          </div>;
}
return null;
})}
        <form ref={form} onSubmit={sendEmail} className='w-full flex flex-col justify-center items-center'>
          {
            products.map((e, index) => {
            if(cartItems[e.id]>0)
              {
               return <div className="flex justify-center items-center">
                 <input name={`order_details`} type='text' value={e.name} hidden /> 
                 <input value={cartItems[e.id]} name={`order_quantity`} type='text' hidden />
                 <input type="text" name='order_color' value={localStorage.getItem('colorss')} hidden />
               </div>
              }
              return null;
})
          }
          <h1 className='bold md:text-3xl text-2xl text-primary-multiply-color'>Please enter your address:</h1>
          <textarea type="text" name='user_address' className='border my-5 border-primary-multiply-color w-[60%] rounded h-40' />
          <input type="text" name='order_details' value='' className='hidden' />
          <input type="text" name='user_name' value={userr?.firstname} className='hidden' />
          <input type="text" name='user_number' value={userr?.number} className='hidden' />
          <input type="text" name='user_email' value={user?.email} className='hidden' />
          <input type="text" name='order_price' className='hidden' value={getTotalCartAmountWithShipping()} />
            <div className="flex w-full justify-around items-center my-2">
            <h1 className=' text-primary-multiply-color text-2xl regular'>Total Price</h1>
            <h1 className=' text-primary-multiply-color text-2xl regular'>L.E {getTotalCartAmount()}</h1>
            </div>
            <div className="flex w-full justify-around items-center my-2">
            <h1 className=' text-primary-multiply-color text-2xl regular'>Shipping`</h1>
            <h1 className=' text-primary-multiply-color text-2xl regular'>L.E {40}</h1>
            </div>
            <div className="flex w-full justify-around items-center my-2">
            <h1 className=' text-primary-multiply-color text-2xl regular'>Total Price</h1>
            <h1 className=' text-primary-multiply-color text-2xl regular'>L.E {getTotalCartAmountWithShipping()}</h1>
            </div>
            <button type="submit" className="flex cursor-pointer justify-center items-center gap-4 w-[60%]  rounded text-white bg-primary-multiply-color px-4 py-2 my-3 hover:scale-105 transition-all duration-300 ease-in-out">Place Order</button>
        </form>
          <Link to="/" className='w-[60%]'>
            <button className='flex justify-center items-center gap-4 w-full  rounded text-white bg-primary-multiply-color px-4 py-2 my-3 hover:scale-105 transition-all duration-300 ease-in-out'>Add More Items <PlusCircle /></button>
            </Link>
          </div>
      </div>
    </div>
  )
}

export default Checkout