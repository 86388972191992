import { Link } from "react-router-dom"
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';
import accessImg from '../../assets/accessories-head.jpeg';
import handImg from '../../assets/handbags-head.jpeg';
import makeImg from '../../assets/make-up-head.jpeg';

const SecondPage = () => {
  useEffect(() => {
      gsap.registerPlugin(ScrollTrigger);
      gsap.from('.mrx', {scrollTrigger: {
        trigger: '.mrx',
        toggleActions: 'play none none reverse',
        start: 'top 70%',
      },opacity: 0, y: 100});
      gsap.from('.mrx2', {scrollTrigger: {
        trigger: '.mrx2',
        toggleActions: 'play none none reverse',
        start: 'top 70%'
      },opacity: 0, y: 100});
      gsap.from('.mrx3', {scrollTrigger: {
        trigger: '.mrx3',
        toggleActions: 'play none none reverse',
        start: 'top 70%'
      },opacity: 0, y: 100});
    // gsap.from('.mi', {opacity: 0, delay: .4});
  }, [])
  const directPage = (loc) => {
    window.location.assign(`${loc}`)
  }
  return (
    <div className="w-full h-fit md:pt-16 pt-36 text-primary-color flex items-center flex-wrap justify-center light mb-28">
      <div className="link-cont m-5  flex flex-col gap-2 justify-center items-center mrx">
      <img src={handImg} alt="h" className="h-[20rem] w-[22rem] rounded object-cover" />
        <h1 className="bold text-center text-primary-multiply-color">Premium Bags</h1>
        <p className="w-3/4 text-center text-black">HandBags crafted specially for you</p>
       <Link><button className="bg-primary-multiply-color transition-all duration-300 hover:bg-white hover:border hover:border-primary-multiply-color p-2 rounded regular text-white hover:text-primary-multiply-color" onClick={() => {directPage("hand-bags")}}>See More</button></Link>
      </div>
      <div className="link-cont m-5  flex flex-col gap-2 justify-center items-center mrx2">
        <img src={accessImg} alt="a" className="h-[20rem] w-[22rem] rounded object-cover" />
        <h1 className="bold text-center text-primary-multiply-color">Best Accessories</h1>
        <p className="w-3/4 text-center text-black">Accessorize from bella.</p>
        <Link><button className="bg-primary-multiply-color transition-all duration-300 hover:bg-white hover:border hover:border-primary-multiply-color p-2 rounded regular text-white hover:text-primary-multiply-color"onClick={() => {directPage("accessories")}}>See More</button></Link>
      </div>
      <div className="link-cont m-5  flex flex-col gap-2 justify-center items-center mrx3">
        <img src={makeImg} alt="m" className="h-[20rem] w-[22rem] rounded object-cover" />
        <h1 className="bold text-center text-primary-multiply-color">Premium Make Up</h1>
        <p className="w-3/4 text-center text-black">Top Shelf Make up from bella.</p>
        <Link><button className="bg-primary-multiply-color transition-all duration-300 hover:bg-white hover:border hover:border-primary-multiply-color p-2 rounded regular text-white hover:text-primary-multiply-color"onClick={() => {directPage("make-up")}}>See More</button></Link>
      </div>
      </div>
  )
}

export default SecondPage