// import React from 'react'
// import { useTranslation } from 'react-i18next';
import FilterComp from '../FilterComp';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';
import bellaLogo from '../../assets/bella-full.png'
import bellaTri from '../../assets/header-side.png'
import "./Hero.css"

gsap.registerPlugin(ScrollTrigger);
const Hero = () => {
   useEffect(() => {
     gsap.from('.mre', {opacity: 0, y: 100});
     gsap.from('.mi', {opacity: 0, delay: .4});
   }, [])
  const directPage = (loc) => {
    window.location.assign(`${loc}`)
  }
   // const { t } = useTranslation();
  return (
    <div className='header-container relative h-screen flex justify-center pb-6 items-center flex-col regular text-white'>
      {/* <img src={headerImg} className='video absolute h-[100%] w-screen top-0 left-0 right-0 bottom-0 opacity-100 -z-10 object-cover ' alt=''></img> */}
      <div className="overlay absolute h-[82%] w-full top-0 left-0 right-0 bottom-0 object-cover -z-20 bg-white/90 rounded-b-[70px]"></div>
      <div className="overlay absolute h-full w-full top-0 left-0 right-0 bottom-0 object-cover -z-30 bg-white"></div>
       <div className="absolute left-0 top-0 hidden sm:flex">
          <img src={bellaTri} alt="" width={405} />
        </div>
      <div className="paralax relative transitionnn flex w-full flex-col h-1/2 justify-center items-center sm:items-end lg:items-center text-white">
      <img src={bellaLogo} alt="" width={350} className='mre mx-16' />
      <div className="btns relative m-3 flex gap-3 justify-center items-center text-center mi mx-36">
        <button className='rounded bg-primary-multiply-color p-2 hover:scale-105 hover:bg-white hover:border hover:border-primary-multiply-color hover:text-primary-multiply-color transition-all duration-500' onClick={() => {directPage("fragrances")}}>Shop</button>
        <a href='#categ' className='rounded bg-white border border-primary-multiply-color text-primary-multiply-color p-2 hover:bg-primary-multiply-color hover:text-white transition-all duration-500 hover:scale-105'>Categories</a>
      </div>
      </div>
      <div className="filter-web flex justify-center items-center absolute bottom-0 w-full mi">
       <FilterComp />
      </div>
      {/* <div className="filter-mob flex justify-center items-center w-full">
       <FilterMob />
      </div> */}
      {/* TODO */}
    </div>
  )
}

export default Hero