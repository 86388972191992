import React, { useContext } from 'react'
import './Item.css'
import { Link } from 'react-router-dom'
import { ShoppingCartIcon } from 'lucide-react'
import {ShopContext} from '../../Context/ShopContext';

const Item = (props) => {

  const {addToCart} = useContext(ShopContext);
  const directPage = (loc) => {
    window.location.assign(`${loc}`)
  }
  
  return (
    <div className='item transition-all duration-500 ease-in-out relative flex flex-col gap-4 justify-center items-start regular' key={props.id}>
      <Link onClick={() => {directPage(`/product/${props.id}`)}} style={{ textDecoration: 'none' }}><img onClick={window.scrollTo(0, 0)} src={props.image} alt="products" className='rounded-xl' /></Link>
      <div className="flex justify-between items-center w-full">
      <div className="flex flex-col gap-1">
      <p className='bold text-2xl text-primary-multiply-color'>{props.name}</p>
      <div className="item-prices">
        <div className="item-price-new text-primary-color">L.E {props.new_price}</div>
        <div className="item-price-old">L.E {props.old_price}</div>
      </div>
      </div>
      <button className='border-0 transition-all duration-500 p-3 bg-primary-multiply-color rounded-full text-white hover:bg-white hover:text-primary-multiply-color hover:border hover:border-primary-multiply-color mx-6 hover:scale-110' onClick={() => {addToCart(props.id)}}><ShoppingCartIcon /></button>
      </div>
    </div>
  )
}

export default Item
